<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <Logo />
                </div>
                <div v-html="this.officeAddress"></div>

                <p class="card-text mb-0">{{ this.officeEmail }}</p>
                <p class="card-text mb-0">{{ this.officeMobile }}</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Payslip
                  <span class="invoice-number"
                    ># {{ expenseInfo?.payslip_no }}</span
                  >
                  <template v-if="expenseInfo">
                    <template v-if="expenseInfo?.is_payed">
                      <b-badge pill variant="light-success" class="ml-1"
                        >Paid</b-badge
                      >
                    </template>
                    <template v-else>
                      <b-badge pill variant="light-danger" class="ml-1"
                        >Due</b-badge
                      >
                    </template>
                  </template>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Date Issued:</p>
                  <p class="invoice-date">
                    {{ formatDeadline(expenseInfo?.payslip_created_date) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Due Date:</p>
                  <p class="invoice-date">
                    {{ formatDeadline(expenseInfo?.date) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details added -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="">Department</h6>
                <p class="mb-25">
                  {{ expenseInfo?.department?.data?.name }}
                </p>
                <div class="mt-2">
                  <h6 class="">Project</h6>
                  <template v-if="expenseInfo?.project">
                    <p class="mb-25">
                      {{ expenseInfo?.project?.data?.lead?.data?.title }}
                    </p>
                  </template>
                </div>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">Payment Details:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Total Due:</td>
                        <td>
                          <span class="font-weight-bold">{{
                            expenseInfo?.amount
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Bank Name:</td>
                        <td>{{ accountInfo?.bank_name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Account Name:</td>
                        <td>{{ accountInfo?.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Account Number:</td>
                        <td>{{ accountInfo?.account_number }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Routing Number:</td>
                        <td>{{ accountInfo?.routing_number }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Country:</td>
                        <td>{{ accountInfo?.country?.data?.name }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">IBAN:</td>
                        <td>
                          {{
                            accountInfo?.iban_number
                              ? accountInfo?.iban_number
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">SWIFT code:</td>
                        <td>
                          {{
                            accountInfo?.swift_code
                              ? accountInfo?.swift_code
                              : "N/A"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-table-lite
            responsive
            :items="payslipDescription"
            :fields="['expenseTitle', 'amount', 'remarks']"
          >
            <template #cell(expenseTitle)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.expenseTitle }}
              </b-card-text>
            </template>
            <template #cell(amount)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ `${expenseInfo?.currency} ${data.item.amount}` }}
              </b-card-text>
            </template>
          </b-table-lite>
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">
                      {{ expenseInfo?.amount }}
                    </p>
                  </div>

                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Grand Total:</p>
                    <p class="invoice-total-amount">
                      {{ expenseInfo?.amount }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span
              >This payslip was generated for this individual expense and
              authority has the right to cancel it!</span
            >
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Payslip
          </b-button> -->

          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->

          <!-- Button: Add Payment -->
          <template
            v-if="$permissionAbility(PAYSLIP_PAYMENT_ACCESS, permissions)"
          >
            <b-button
              v-b-toggle.sidebar-invoice-add-payment
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="expenseInfo?.is_payed"
            >
              Make Payment
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <payslip-sidebar-send-payslip />
    <template v-if="expenseInfo">
      <payslip-sidebar-add-payment
        :expenseInfo="expenseInfo"
        v-on:loadExpenseInfo="updateExpenseInfo"
      />
    </template>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BBadge,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";

import { mapGetters } from "vuex";
import { PAYSLIP_PAYMENT_ACCESS } from "@/helpers/permissionsConstant";
// import invoiceStoreModule from "../invoiceStoreModule";
import PayslipSidebarSendPayslip from "./PayslipSidebarSendInvoice.vue";
import PayslipSidebarAddPayment from "./PayslipSidebarAddPayment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "PayslipView",
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,

    Logo,
    PayslipSidebarSendPayslip,
    PayslipSidebarAddPayment,
  },
  data() {
    return {
      payslipDescription: [],
      expenseInfo: "",
      accountInfo: "",
      officeAddress: "",
      officeEmail: "",
      officeMobile: "",
      PAYSLIP_PAYMENT_ACCESS,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },
  async created() {
    this.loadItems();
  },

  methods: {
    printInvoice() {
      // window.print();
    },
    async loadItems() {
      try {
        this.payslipDescription = [];
        const [expenseInfo, getSetting] = await Promise.all([
          this.getExpenseInfo(),
          this.getSetting({
            select: "account_id,address,email,mobile",
          }),
        ]);

        this.expenseInfo = expenseInfo?.data?.data;

        this.accountInfo = this.expenseInfo?.account?.data;

        this.officeAddress = getSetting?.data?.data?.address;
        this.officeEmail = getSetting?.data?.data?.email;
        this.officeMobile = getSetting?.data?.data?.mobile;

        const item = {
          expenseTitle: this.expenseInfo?.title,
          amount: this.expenseInfo?.amount,
          remarks: this.expenseInfo?.remarks,
        };
        this.payslipDescription.push(item);

        const id = this.$route.params.id;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    updateExpenseInfo() {
      this.loadItems();
    },

    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all?include=country");
    },
    statusVariant(value) {
      if (value) {
        return "light-success";
      }
      return "light-danger";
    },
    async getExpenseInfo() {
      const id = this.$route.params.id;
      return await this.$api.get(
        `api/expenses/${id}?include=department,project.lead,account.country`
      );
    },
    formatDeadline(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
