var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "sidebar-send-invoice",
      "sidebar-class": "sidebar-lg",
      "bg-variant": "white",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v("Send Invoice")]), _c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1), _c('b-form', {
          staticClass: "p-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "From",
            "label-for": "from"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "from",
            "trim": "",
            "type": "email"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "To",
            "label-for": "to"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "to",
            "trim": "",
            "type": "email"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Subject",
            "label-for": "subject"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "subject"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Message",
            "label-for": "message"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "message",
            "rows": "12",
            "trim": ""
          }
        })], 1), _c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', {
          staticClass: "ml-50"
        }, [_vm._v("Invoice Attached")])], 1), _c('div', {
          staticClass: "d-flex mt-2"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "primary",
            "type": "submit"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Send ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Cancel ")])], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }