<template>
  <b-sidebar
    id="sidebar-invoice-add-payment"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Proceed To Payment</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="payExpenseValidation">
        <b-form v-on:submit.prevent="payExpenseValidationForm" class="p-2">
          <b-row>
            <!-- select account -->
            <b-col md="12" lg="12" xs="12">
              <b-form-group label="Account *">
                <ValidationProvider
                  name="account"
                  v-slot="{ errors }"
                  vid="account_id"
                  rules="required"
                >
                  <v-select
                    placeholder="Choose Account"
                    v-model="accountId"
                    :options="accountIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="12" lg="12" xs="12">
              <b-form-group label="Payslip Ammount *">
                <b-form-input
                  id="name"
                  type="text"
                  v-model="payslipAmount"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- loading button -->
            <div class="d-flex mt-2 ml-2">
              <template v-if="isLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>
              <template v-else>
                <b-button
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                >
                  Pay
                </b-button>
              </template>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-row></b-form
        >
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { required, max, size, ext, max_value, min_value } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCol,
    BRow,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: ["expenseInfo"],
  data() {
    return {
      accountIdOption: [],
      accountId: this.expenseInfo?.account_id,
      remarks: "",
      isLoading: false,
      payslipAmount: this.expenseInfo?.amount,
    };
  },
  computed: {
    getExpenseInfo() {
      // this.accountId = this.expenseInfo?.account_id;
      return this.expenseInfo;
    },
  },
  async created() {
    try {
      const [accounts] = await Promise.all([this.getActiveAccountInfo()]);

      this.accountIdOption = (accounts?.data?.data || []).map((item) => {
        let name = `${item?.name} (${item?.currency} ${item?.balance})`;
        let id = item?.id;

        return {
          name,
          id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error 2",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all");
    },
    payExpenseValidationForm: async function () {
      this.$refs.payExpenseValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            const expenseId = this.$route.params.id;

            await this.$api.post(`/api/expenses/${expenseId}/pay`, {
              account_id: this.accountId,
            });

            this.isLoading = false;
            this.$emit("loadExpenseInfo");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Expense Successfully Paid",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.payExpenseValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
